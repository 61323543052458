import { useContext } from "react";
import { ApiContext } from "../account/authenticatedAPI";

import type { DClub_Artist } from "../../apiTypes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setArtists } from "./artistsSlice";
import getAll from "../../utils/getAll";
import { decode } from "he";
import React from "react";

const useArtistsSlice = (ids: number[]): [boolean, DClub_Artist[]] => {
  const api = useContext(ApiContext);
  const allArtistsLoaded = useAppSelector((state) => state.artists.loaded);
  const allArtists = useAppSelector((state) => state.artists.artists);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  if (allArtistsLoaded) {
    return [true, Object.values(allArtists)];
  }
  // Compute missing keys
  const missingKeys = ids.filter(
    (id) =>
      Object.keys(allArtists)
        .map((k) => parseInt(k, 10))
        .indexOf(id) < 0
  );

  const loaded = missingKeys.length === 0;

  if (!isLoading && !loaded) {
    setIsLoading(true);
    api.then((api) =>
      getAll(api.artist().include(missingKeys).perPage(100)).then(
        (artists: DClub_Artist[]) => {
          dispatch(
            setArtists(
              artists
                .map((artist) => ({
                  ...artist,
                  title: { rendered: decode(artist.title.rendered) },
                }))
                // If despite our request we can't find the artists
                // it probably has been deleted in the db
                .concat(
                  missingKeys
                    .filter((id) => artists.map((a) => a.id).indexOf(id) < 0)
                    .map((id) => {
                      return {
                        id: id,
                        title: { rendered: "ARTISTE INVALIDE" },
                      };
                    })
                )
            )
          );
          setIsLoading(false);
        }
      )
    );
  }

  return [loaded, Object.values(allArtists)];
};

export default useArtistsSlice;
