import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useAppSelector } from "./app/hooks";
import Login from "./Login";
import "./App.css";
import { useDispatch } from "react-redux";
import { logOff } from "./features/account/accountSlice";
import useAPI, { ApiContext } from "./features/account/authenticatedAPI";
import Loading from "./Loading";
import DateAdapter from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import AuthenticatedApp from "./AuthenticatedApp";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import { AccountCircle, DateRange, ExitToApp } from "@mui/icons-material";
import { useState, cloneElement } from "react";
import { ListItemIcon, Menu, MenuItem, SvgIcon } from "@mui/material";
import Logo from "./Logo";
import getWpUrl from "./utils/getWpUrl";
import { wordpress } from "@wordpress/icons";
import fr from "date-fns/locale/fr";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <span color="inherit">D! Club</span> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#f23b3b",
    },
    success: {
      main: "#2EAA32",
    },
  },
});
function App() {
  const loggedIn = useAppSelector((s) => s.account.logged_in);
  const [api, authenticated] = useAPI();
  const dispatch = useDispatch();

  const history = useHistory();
  const toggleDrawer = () => {
    history.push("/calendar");
  };

  const [accoutMenuAnchor, setAccoutMenuAnchor] = useState<any>(null);

  const handleClick = (event: any) => {
    setAccoutMenuAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAccoutMenuAnchor(null);
  };
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={DateAdapter} locale={fr}>
          <ApiContext.Provider value={api}>
            {
              // TODO delete app password in rest
              <div
                style={{
                  display: "flex",
                  flexFlow: "column nowrap",
                  height: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <CssBaseline />
                  <AppBar position="absolute" open={false}>
                    <Toolbar
                      sx={{
                        pr: "24px", // keep right padding when drawer closed
                      }}
                    >
                      <Switch>
                        <Route path="/edit/:date?">
                          <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                              marginRight: "36px",
                              // TODO hide when necessarry
                            }}
                          >
                            <DateRange />
                          </IconButton>
                        </Route>
                      </Switch>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                      >
                        <Switch>
                          <Route path="/edit/:date?" children="Édition" />
                          <Route
                            path="/calendar/:date?"
                            children="Calendrier"
                          />
                          <Route
                            path="/login"
                            children="Administration D! Club"
                          />
                        </Switch>
                      </Typography>
                      <IconButton
                        color="inherit"
                        href={getWpUrl() + "wp-admin"}
                      >
                        <SvgIcon
                          children={cloneElement(wordpress, {
                            viewBox: "0 0 20 20",
                          })}
                        />
                      </IconButton>
                      <IconButton
                        color="inherit"
                        href={getWpUrl()}
                        target="_blank"
                      >
                        <SvgIcon>
                          <Logo
                            outerColor={theme.palette.primary.main}
                            innerColor={theme.palette.primary.contrastText}
                          />
                        </SvgIcon>
                      </IconButton>
                      {loggedIn && (
                        <>
                          <IconButton color="inherit" onClick={handleClick}>
                            <AccountCircle />
                          </IconButton>
                          <Menu
                            id="account-menu"
                            anchorEl={accoutMenuAnchor}
                            keepMounted
                            open={Boolean(accoutMenuAnchor)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                dispatch(logOff());
                              }}
                            >
                              <ListItemIcon>
                                <ExitToApp fontSize="small" />
                              </ListItemIcon>
                              Déconnexion
                            </MenuItem>
                          </Menu>
                        </>
                      )}
                    </Toolbar>
                  </AppBar>
                  <Box
                    component="main"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[100]
                          : theme.palette.grey[900],
                      flexGrow: 1,
                      height: "100vh",
                      overflow: "auto",
                    }}
                  >
                    <Toolbar />
                    <Container
                      maxWidth="xl"
                      sx={{ mt: 4, mb: 4 }}
                      style={{
                        flexGrow: 1,
                        height: "100%",
                        display: "flex",
                        flexFlow: "column nowrap",
                      }}
                    >
                      {loggedIn ? (
                        authenticated ? (
                          <AuthenticatedApp />
                        ) : (
                          <Loading />
                        )
                      ) : (
                        <Redirect to="/login" />
                      )}
                      <Switch>
                        <Route path="/login">
                          <Login />
                        </Route>
                      </Switch>
                      <Copyright sx={{ pt: 4 }} style={{ marginTop: "auto" }} />
                    </Container>
                  </Box>
                </Box>
              </div>
            }
          </ApiContext.Provider>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
