import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Grid, IconButton } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import Loading from "../../Loading";
import { ApiContext } from "../account/authenticatedAPI";
import type { DClub_Date } from "../../apiTypes";
import { format, parse } from "date-fns";
import { Prompt } from "react-router";
import { useBeforeunload } from "react-beforeunload";
import PanelSheets, { filterViewAccess, Views } from "./PanelSheets";
import ViewSelector from "./ViewSelector";
import { useHistory, useParams } from "react-router-dom";
import DateSlector from "./DateSelector";
import useDatePlacesSlice from "../datePlaces/useDatePlacesSlice";
import useDateStylesSlice from "../dateStyles/useDateStylesSlice";
import useRole from "../account/useRole";
import useDates from "./useDates";

enum ORDER {
  dclub = "d-club",
  dClubLive = "d-club-live",
  abc = "abc",
  abcLive = "abc-live",
}
export { ORDER };
interface DateState {
  date: Date;
  dates: {
    [key in ORDER]?: DClub_Date;
  };
}
const dateState = (date: Date): DateState => {
  return {
    date: date,
    dates: Object.fromEntries(Object.keys(ORDER).map((slug) => [slug, null])),
  };
};
interface PanelParams {
  date: string;
}
function Panel() {
  const api = useContext(ApiContext);
  const { date: propDate } = useParams<PanelParams>();
  const parsedPropDate = useMemo(
    () => (propDate ? parse(propDate, "yyyyMMdd", new Date()) : new Date()),
    [propDate]
  );
  const [reload, setReload] = useState(false);
  const [styleDateLoaded, styleDate] = useDateStylesSlice();
  const [placeDateLoaded, placeDate] = useDatePlacesSlice();
  const [loadedDate, setLoadedDate] = useState("");
  const [loadingDate, setLoadingDate] = useState("");
  const [date, setDate] = useState<DateState>(dateState(parsedPropDate));
  const [dirty, setDirty] = useState(
    Object.fromEntries(Object.values(ORDER).map((slug) => [slug, false]))
  );
  const [roleLoaded, role] = useRole();
  const [view, setView] = useState(Views.DATE);

  useEffect(() => {
    if (roleLoaded && role) {
      const views = filterViewAccess(role);
      setView(views[0]);
    }
  }, [roleLoaded, role]);

  const [datesLoaded, dates] = useDates({
    date: format(date.date, "yyyyMMdd"),
  });
  useEffect(() => {
    const newDate = parsedPropDate;
    setDate((date) => {
      return { ...date, date: newDate };
    });
  }, [setDate, parsedPropDate]);
  const history = useHistory();
  useEffect(
    () => history.replace("/edit/" + format(date.date, "yyyyMMdd")),
    [history, date.date]
  );
  useEffect(() => {
    if (datesLoaded && format(date.date, "yyyyMMdd") !== loadingDate) {
      setLoadedDate("");
      setLoadingDate(format(date.date, "yyyyMMdd"));

      const indexedDates = Object.fromEntries(
        Object.values(ORDER).map((slug) => [
          slug,
          dates.find((date) =>
            date.date_place.find((place) => place.slug === slug)
          ),
        ])
      );

      setDate((currentDates) => {
        if (
          format(currentDates.date, "yyyyMMdd") ===
          format(date.date, "yyyyMMdd")
        ) {
          setLoadedDate(format(currentDates.date, "yyyyMMdd"));
          if (format(date.date, "yyyyMMdd") === loadingDate) setLoadingDate("");
          return { date: date.date, dates: indexedDates };
        } else return currentDates;
      });
      setReload(false);
    }
  }, [api, date.date, reload, loadingDate, datesLoaded, dates]);

  const cb = useMemo(
    () =>
      Object.fromEntries(
        Object.values(ORDER).map((slug) => [
          slug,
          (childDirty: boolean) =>
            setDirty((dirty) => {
              const update: { [key in ORDER]?: boolean } = {};
              update[slug] = childDirty;
              return { ...dirty, ...update };
            }),
        ])
      ),
    [setDirty]
  );

  const oneDirty = Object.values(dirty).reduce((a, b) => a || b);
  useBeforeunload((event) => {
    if (oneDirty) event.preventDefault();
  });
  const loaded =
    loadedDate === format(date.date, "yyyyMMdd") &&
    datesLoaded &&
    placeDateLoaded &&
    styleDateLoaded &&
    roleLoaded;
  return (
    <>
      <Prompt
        when={oneDirty}
        message="Des données n'ont pas été enregistrées"
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ViewSelector value={view} setValue={setView} />
          <DateSlector
            onChange={useCallback(
              (newValue) =>
                setDate((date) => {
                  return { ...date, date: newValue };
                }),
              [setDate]
            )}
            value={date.date}
            disabled={oneDirty}
          />
          <IconButton
            onClick={() => setReload(true)}
            disabled={oneDirty}
            size="large"
          >
            <Refresh />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {loaded ? (
            <PanelSheets
              dates={date.dates}
              date={date.date}
              cb={cb}
              placeDate={placeDate}
              styleDate={styleDate}
              view={view}
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Panel;
