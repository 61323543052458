import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Theme } from "@mui/material";
import type {
  DClub_Artists_Tracking_Stage,
  DClub_Artists_Tracking,
} from "../../apiTypes";
import styled from "styled-components";
import { withTheme } from "@mui/styles";
import { ReactElement } from "react";

interface ArtistsTrackingProps {
  value: DClub_Artists_Tracking[];
  header?: ReactElement;
  small?: boolean;
}

const Dot = withTheme(styled.span`
  font-size: 1.8em;
  color: ${({ checked, theme }: { checked: boolean; theme: Theme }) =>
    checked ? theme.palette.success.main : theme.palette.error.main};
`);
const TrackingCheckbox = ({
  tracking,
  name,
}: {
  tracking: DClub_Artists_Tracking_Stage[];
  name: DClub_Artists_Tracking_Stage;
}) => {
  return <Dot checked={tracking.indexOf(name) > -1}> • </Dot>;
};
function ArtistsTracking({
  value,
  header = undefined,
  small = false,
}: ArtistsTrackingProps) {
  return (
    <TableContainer
      style={{ overflowX: "initial", height: small ? undefined : "25em" }}
    >
      <Table aria-label="artisits table" size="small" component="div">
        {header}
        <TableBody component="div">
          {value.map(({ tracking, artiste }, index) => (
            <TableRow key={index} component="div">
              <TableCell
                style={small ? { padding: 0, lineHeight: 0.9 } : undefined}
                component="div"
              >
                {artiste?.title.rendered}
              </TableCell>
              <TableCell
                style={small ? { padding: 0, lineHeight: 0.9 } : undefined}
                component="div"
                align="right"
              >
                <TrackingCheckbox name="contract" tracking={tracking} />
                <TrackingCheckbox name="pay" tracking={tracking} />
                <TrackingCheckbox name="flight" tracking={tracking} />
                <TrackingCheckbox name="hotel" tracking={tracking} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ArtistsTracking;
