import { useEffect, useState } from "react";
import MultilineText from "../fields/MultilineText";
import withSheetLoader, { InjectedLoadedSheetProps } from "./SheetLoader";
import { withSheetSaver } from "./SheetSaver";
import { WP_Post } from "../../../apiTypes";

interface DClub_NightSecurity extends WP_Post {
  acf: {
    soiree_organisation_staff: string;
  };
}
export type { DClub_NightSecurity };
interface SheetNightSecurityProps
  extends InjectedLoadedSheetProps<DClub_NightSecurity> {
  disabled: boolean;
}

function SheetNightSecurity({
  data,
  setParentData,
  disabled = false,
}: SheetNightSecurityProps) {
  const [orgaStaff, setOrgaStaff] = useState(
    data.acf.soiree_organisation_staff
  );

  useEffect(() => {
    setParentData({
      acf: {
        soiree_organisation_staff: orgaStaff,
      },
    });
  }, [setParentData, orgaStaff]);
  return (
    <>
      <MultilineText
        disabled={disabled}
        label="Organisation Staff"
        value={orgaStaff}
        setValue={setOrgaStaff}
      />
    </>
  );
}

const empty = {
  id: 0,
  acf: {
    soiree_organisation_staff: "",
  },
};

// @ts-ignore
const x = withSheetLoader(
  "date_night_sheet_sec",
  empty,
  withSheetSaver<DClub_NightSecurity, SheetNightSecurityProps>(
    "date_night_sheet_sec",
    SheetNightSecurity
  )
);
// @ts-ignore
const PanelSheetNightSecurity = x as React.ComponentType<
  Partial<SheetNightSecurityProps>
>;
export { PanelSheetNightSecurity };
