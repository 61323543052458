import TaxonomyInput from "../fields/TaxonomyInput";
import MultilineText from "../fields/MultilineText";
import { InjectedLoadedSheetProps } from "./SheetLoader";
import type { DClub_Date, DClub_Place_Date } from "../../../apiTypes";
import SimpleText from "../fields/SimpleText";
import useDateStylesSlice from "../../dateStyles/useDateStylesSlice";
import { TableCell, TableHead, TableRow, TextField } from "@mui/material";
import ArtistsTracking from "../../artists/ArtistsTracking";
import { TimePicker } from "@mui/lab";
import SimpleSelect from "../fields/SimpleSelect";
import { AgeNames } from "./SheetDate";
import { parse } from "date-fns";
interface SheetBasicInfoProps extends InjectedLoadedSheetProps<DClub_Date> {
  datePlaces: DClub_Place_Date[];
}
/**
 * Unfortunately we need to split it from SheetDate otherwise we would have two Sheet Saver
 * for the same post, which would most likey cause concurrency problems
 */
function SheetBasicInfo({ data, datePlaces }: SheetBasicInfoProps) {
  const [, styles] = useDateStylesSlice();
  const doors = data.acf.date_ouverture_des_portes;

  return (
    <>
      <TaxonomyInput
        disabled
        taxonomies={datePlaces}
        value={data.date_place}
        label="Lieu"
      />
      <TaxonomyInput
        disabled
        taxonomies={styles}
        value={data.date_style}
        label="Style"
      />
      <SimpleText disabled label="Titre" value={data.title.rendered} />
      <ArtistsTracking
        header={
          <TableHead component="div">
            <TableRow component="div">
              <TableCell component="div">Artiste</TableCell>
              <TableCell component="div" align="right"></TableCell>
            </TableRow>
          </TableHead>
        }
        value={data.acf.tracking_artists ? data.acf.tracking_artists : []}
      />
      <MultilineText
        disabled
        label="Remarque générale"
        value={data.acf.date_remarque_generale}
      />
      <TimePicker
        label="Ouverture des portes"
        clearable
        disabled
        value={
          doors && doors.length > 0
            ? parse(doors, "HH:mm:ss", new Date())
            : null
        }
        renderInput={(params) => <TextField variant="filled" {...params} />}
        onChange={() => {}}
      />
      <SimpleSelect
        disabled
        setValue={() => {}}
        label="Âge"
        options={AgeNames}
        value={data.acf.date_age}
      />
    </>
  );
}

export default SheetBasicInfo as React.ComponentType<
  Partial<SheetBasicInfoProps>
>;
