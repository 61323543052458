import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../account/authenticatedAPI";
import { WP_Post } from "../../../apiTypes";
import { InjectedSheetDataProps, SheetSaverProps } from "./SheetSaver";
import clone from "clone";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

interface InjectedLoadedSheetProps<S> extends InjectedSheetDataProps<S> {
  data: S;
}
export type { InjectedLoadedSheetProps };

function recursiveNullToUndefined(x: any) {
  for (let a in x) {
    if (x[a] === null) x[a] = undefined;
    else if (typeof x[a] === "object") x[a] = recursiveNullToUndefined(x[a]);
  }
  return x;
}

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
function withSheetLoader<S extends WP_Post, P extends SheetSaverProps<S>>(
  postType: string,
  empty: S,
  Component: React.ComponentType<P>
) {
  function SheetLoader({ dateId, newDate, ...props }: SheetSaverProps<S>) {
    const api = useContext(ApiContext);
    const [loaded, setLoaded] = useState(newDate);
    const [data, setData] = useState<S>();
    const childProp: P = {
      ...props,
      dateId,
      newDate,
    } as P;

    useEffect(() => {
      if (newDate && dateId === 0) {
        setData(clone(empty));
        setLoaded(true);
      } else {
        api.then((api) =>
          api[postType]()
            .param("parent", dateId)
            .param("status", "private")
            .then((res: any) => {
              if (res.length === 1) setData(recursiveNullToUndefined(res[0]));
              else if (res.length === 0) setData(clone(empty));
              else throw new Error();
              setLoaded(true);
            })
        );
      }
    }, [api, dateId, newDate]);

    return loaded && data !== undefined ? (
      <Component {...childProp} data={data} dateId={data.id} />
    ) : (
      <ProgressContainer>
        <CircularProgress />
      </ProgressContainer>
    );
  }
  return SheetLoader;
}

export default withSheetLoader;
