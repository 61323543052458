import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { DClub_Artist } from "../../apiTypes";

interface ArtistsState {
  artists: { [k: number]: DClub_Artist };
  loaded: boolean;
  isLoading: boolean;
}

const initialState = {
  artists: {},
  loaded: false,
  isLoading: false,
} as ArtistsState;

const artistsSlice = createSlice({
  name: "artists",
  initialState,
  reducers: {
    setArtists(
      { artists: currentArtists, ...rest },
      action: PayloadAction<DClub_Artist[]>
    ) {
      // TODO merge
      return {
        artists: {
          ...currentArtists,
          ...Object.fromEntries(action.payload.map((a) => [a.id, a])),
        },
        ...rest,
      };
    },
    setAllArtists(_, action: PayloadAction<DClub_Artist[]>) {
      // TODO merge
      return {
        artists: Object.fromEntries(action.payload.map((a) => [a.id, a])),
        loaded: true,
        isLoading: false,
      };
    },
    setIsLoading(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const { setArtists, setAllArtists, setIsLoading, reset } =
  artistsSlice.actions;
export default artistsSlice.reducer;
