import { PropsWithChildren } from "react";
import styled from "styled-components";
import Loading from "../../Loading";

interface OverlayLoaderProps {
  loading: boolean;
}
const StyledLoading = styled(Loading)`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #0003;
`;
function OverlayLoader({
  loading,
  children,
}: PropsWithChildren<OverlayLoaderProps>) {
  return (
    <div style={{ height: "100%", position: "relative" }}>
      {loading && <StyledLoading />}
      {children}
    </div>
  );
}

export default OverlayLoader;
