import Button from "@mui/material/Button";
import { DropzoneDialog } from "material-ui-dropzone";
import { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../account/authenticatedAPI";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";

interface FileUploadProps {
  label: React.ReactNode;
  acceptedFiles: string[];
  id: number;
  onChange: (newId: number) => void;
  image?: boolean;
}
function FileUpload({
  label,
  acceptedFiles,
  id,
  onChange,
  image = false,
}: FileUploadProps) {
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachment, setAttachment] = useState(undefined);
  const api = useContext(ApiContext);

  useEffect(() => {
    // @ts-ignore
    if (id > 0 && (attachment === undefined || attachment.id !== id)) {
      api.then((api) =>
        api
          .media()
          .id(id)
          .then((res) => {
            setLoading(true);
            setAttachment(res);
          })
      );
    } else setLoading(false);
  }, [api, id, attachment, setAttachment]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            {label}
          </Button>
        </Grid>
        <Grid item>{(loading || uploading) && <CircularProgress />}</Grid>
        <Grid item>
          {attachment !== undefined &&
            (image ? (
              <img
                // @ts-ignore
                alt={attachment.title.rendered}
                // @ts-ignore
                src={attachment.media_details.sizes.medium.source_url}
              />
            ) : (
              // @ts-ignore
              <a href={attachment.source_url}>
                {
                  // @ts-ignore
                  attachment.title.rendered
                }
              </a>
            ))}
        </Grid>
      </Grid>
      <DropzoneDialog
        acceptedFiles={acceptedFiles}
        cancelButtonText={"annuler"}
        submitButtonText={"envoyer"}
        dialogTitle="Envoyer un fichier"
        dropzoneText="Déposez un fichier ici ou cliquez"
        maxFileSize={5000000}
        filesLimit={1}
        open={open}
        onClose={() => setOpen(false)}
        showAlerts={false}
        onSave={(files) => {
          setOpen(false);
          setUploading(true);
          api.then((api) =>
            api
              .media()
              .file(files[0])
              .create({})
              .then((res) => {
                setUploading(false);
                setAttachment(res);
                onChange(res.id);
              })
          );
        }}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </>
  );
}

export default FileUpload;
