import clone from "clone";
import EditIcon from "@mui/icons-material/Edit";
import { DClub_File_Repeater } from "../../../apiTypes";
import FileUpload from "./FileUpload";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

interface MultipleFilesProps {
  value: DClub_File_Repeater[];
  setValue: (newValue: DClub_File_Repeater[]) => void;
  label: string;
}
function MultipleFiles({ value, setValue, label }: MultipleFilesProps) {
  // TODO add remove button
  return (
    <Box>
      <Grid alignItems="center" container spacing={2}>
        <Grid item xs={9}>
          <Typography align="left" variant="h6">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <IconButton onClick={() => setValue([...value, { file: 0 }])}>
            <Add />
          </IconButton>
        </Grid>
        {value.map(({ file }, index) => (
          <Grid item xs={12}>
            <FileUpload
              label={<EditIcon />}
              acceptedFiles={[]}
              id={file}
              onChange={(newId) => {
                const newValue = clone(value);
                newValue[index] = { file: newId };
                setValue(newValue);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
export default MultipleFiles;
