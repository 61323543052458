import * as React from "react";

interface SvgDClubLogoProps extends React.SVGProps<SVGSVGElement> {
  innerColor: string;
  outerColor: string;
}
function SvgDClubLogo({ innerColor, outerColor, ...props }: SvgDClubLogoProps) {
  return (
    <svg viewBox="0 0 1369.911 1369.913" {...props}>
      <defs>
        <clipPath
          id="DClub-logo-redwhite_svg__a"
          clipPathUnits="userSpaceOnUse"
        >
          <path d="M0 1080h1080V0H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#DClub-logo-redwhite_svg__a)"
        transform="matrix(1.33333 0 0 -1.33333 -35.045 1404.29)"
      >
        <path
          d="M1053.716 539.5c0 283.719-229.999 513.717-513.716 513.717-283.718 0-513.717-229.998-513.717-513.716S256.283 25.785 540 25.785s513.716 229.998 513.716 513.716"
          fill={outerColor}
        />
        <path
          d="M418.442 582.346c22.48 0 51.964-9.36 76.306-36.033 23.96-26.293 41.725-68.902 41.725-134.992-.428-132.536-67.446-186.408-114.318-186.408H342.945V582.346h75.497"
          fill={innerColor}
        />
        <path
          d="M781.068 406.201c0 47.822 38.439 86.692 85.69 86.692 47.394 0 86.215-38.87 86.215-86.692 0-47.775-38.821-86.645-86.214-86.645-47.252 0-85.691 38.87-85.691 86.645m168.666 442.721l-10.29 13.575-69.875-341.049h-9.812l-85.74 391.706-5.19.93c-8.384 1.43-22.436 4.668-33.201 10.691-10.811 6.646-18.338 14.101-18.338 25.842-.381 15.457 15.053 33.224 31.485 45.895l10.24 7.502-11.668 5.12c-65.162 29.08-134.992 44.083-207.107 44.083-283.51 0-513.954-230.493-513.954-513.977 0-126.442 46.345-245.952 129.798-340.12l13.527-15.007-.476 514.863v3.785h275.982c113.89 0 274.984-89.026 275.554-289.58-.57-217.373-142.515-311.947-284.461-312.447H302.266l-3.813-14.553c74.072-39.296 156.952-60.399 241.785-60.399 283.033 0 513.479 230.47 513.479 513.458 0 111.984-36.962 220.681-103.983 309.682"
          fill={innerColor}
        />
      </g>
    </svg>
  );
}

export default SvgDClubLogo;
