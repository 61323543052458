import FullCalendar, { EventContentArg } from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import { useEffect, useReducer, useRef, useState } from "react";
import { DClub_Date } from "../../apiTypes";
import { format, parse } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
import MonthSlector from "./MonthSelector";
import OverlayLoader from "./OverlayLoader";
import ArtistsTracking from "../artists/ArtistsTracking";
import {
  Card,
  CardHeader,
  Grid,
  Paper,
  useTheme,
  Theme,
  CardContent,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import he from "he";
import useDates from "../dates/useDates";
import { useDispatch } from "react-redux";

interface DateState {
  start: Date;
  end: Date;
  initialized: boolean;
}
interface DateStateActionSetDate {
  type: "setDate";
  start: Date;
  end: Date;
}
interface DateStateActionSetDates {
  type: "setDates";
  forStartDate: Date;
  forEndDate: Date;
  payload: DClub_Date[];
}
type DateStateAction = DateStateActionSetDate | DateStateActionSetDates;
function reducer(state: DateState, action: DateStateAction) {
  switch (action.type) {
    case "setDate":
      return {
        start: action.start,
        end: action.end,
        dates: [],
        initialized: true,
      };
    case "setDates":
      if (
        state.start === action.forStartDate &&
        state.end === action.forEndDate
      )
        return { ...state, dates: action.payload };
      else return state;
    default:
      throw new Error();
  }
}

const dateState = (start: Date): DateState => {
  return {
    start: start,
    end: new Date(),
    initialized: false,
  };
};
interface CalendarParams {
  date: string;
}
function Calendar() {
  const { date } = useParams<CalendarParams>();
  const newDate = date ? parse(date, "yyyyMM", new Date()) : new Date();
  const [{ start, end }, dispatch] = useReducer(reducer, newDate, dateState);
  const history = useHistory();
  const calendarRef = useRef(null);

  const goToDate = (date: Date) => {
    history.push("/edit/" + format(date, "yyyyMMdd"));
  };

  const [loaded, dates] = useDates(
    {
      start_date: format(start, "yyyyMMdd"),
      end_date: format(end, "yyyyMMdd"),
    },
    calendarRef.current !== null
  );

  useEffect(() => {
    if (calendarRef.current) {
      // @ts-ignore
      const api = calendarRef.current.getApi();
      const newDate: Date = api.getDate();
      history.replace("/calendar/" + format(newDate, "yyyyMM"));
    }
  }, [history, start]);

  const [month, setMonth] = useState(newDate);
  useEffect(() => {
    if (calendarRef.current) {
      // @ts-ignore
      const api = calendarRef.current.getApi();
      api.gotoDate(month);
    }
  }, [month]);

  const theme = useTheme<Theme>();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <MonthSlector value={month} onChange={setMonth} />
      </Grid>
      <style>
        {`.dclub_calendar table tr:first-child th {
  border-top: 0;
}
.dclub_calendar table tr:last-child td {
  border-bottom: 0;
}
.dclub_calendar table tr td:first-child,
.dclub_calendar table tr th:first-child {
  border-left: 0;
}
.dclub_calendar table tr td:last-child,
.dclub_calendar table tr th:last-child {
  border-right: 0;
  `}
      </style>
      <Grid item xs={12} style={{ flexGrow: 1 }}>
        <Paper style={{ height: "100%" }}>
          <OverlayLoader loading={!loaded}>
            <FullCalendar
              firstDay={1}
              contentHeight="auto"
              viewClassNames="dclub_calendar"
              headerToolbar={false}
              ref={calendarRef}
              initialDate={newDate}
              datesSet={(args) => dispatch({ type: "setDate", ...args })}
              height="100%"
              eventBorderColor="transparent"
              eventBackgroundColor="transparent"
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              dateClick={(info) => goToDate(info.date)}
              eventClick={(info) => {
                if (info && info.event.start) goToDate(info.event.start);
              }}
              eventContent={renderEventContent}
              events={dates.map((event) => {
                return {
                  event: event,
                  date: event.acf.date,
                  theme: theme,
                };
              })}
            />
          </OverlayLoader>
        </Paper>
      </Grid>
    </Grid>
  );
}
const style = { padding: "4px" };
const InlineTypo = styled(Typography)``;
const Nl2Br = ({ children }: { children: string }) => (
  <>
    {children.split("\n").map((item, key) => (
      <span key={key}>
        {item}
        <br />
      </span>
    ))}
  </>
);
function renderEventContent(eventInfo: EventContentArg) {
  const date: DClub_Date = eventInfo.event.extendedProps.event;
  const theme: Theme = eventInfo.event.extendedProps.theme;
  return (
    <Card
      style={{ whiteSpace: "initial", border: 0, borderRadius: 0 }}
      variant="outlined"
      elevation={0}
      component="div"
    >
      <CardHeader
        style={{
          ...style,
          backgroundColor:
            date.status === "publish"
              ? theme.palette.success.main
              : theme.palette.grey[300],
        }}
        title={
          <>
            <InlineTypo variant="subtitle1" style={{ lineHeight: 1.2 }}>
              {date.date_place.map((place) => place.name).join(", ")}
            </InlineTypo>
            {"   "}
            <InlineTypo variant="subtitle2">
              {date.title.rendered.length > 0 && he.decode(date.title.rendered)}
            </InlineTypo>
          </>
        }
        disableTypography={true}
      />
      <CardContent style={style}>
        {date.acf.tracking_artists && date.acf.tracking_artists.length > 0 && (
          <ArtistsTracking small value={date.acf.tracking_artists} />
        )}
        <div>
          {date.acf.date_options && (
            <>
              <Typography variant="subtitle2">Options</Typography>
              <Typography variant="body2">
                <Nl2Br>{date.acf.date_options}</Nl2Br>
              </Typography>
            </>
          )}
        </div>
        <div>
          {date.acf.date_remarque_generale && (
            <>
              <Typography variant="subtitle2">Remarque</Typography>
              <Typography variant="body2">
                <Nl2Br>{date.acf.date_remarque_generale}</Nl2Br>
              </Typography>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

export default Calendar;
