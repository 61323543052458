import { useEffect, useState } from "react";
import MultilineText from "../fields/MultilineText";
import withSheetLoader, { InjectedLoadedSheetProps } from "./SheetLoader";
import { withSheetSaver } from "./SheetSaver";
import { WP_Post } from "../../../apiTypes";

interface DClub_NightLineUp extends WP_Post {
  acf: {
    line_up: string;
  };
}
export type { DClub_NightLineUp };
interface SheetNightLineUpProps
  extends InjectedLoadedSheetProps<DClub_NightLineUp> {
  disabled?: boolean;
}

function SheetNightLineUp({
  data,
  setParentData,
  disabled = false,
}: SheetNightLineUpProps) {
  const [lineUp, setLineUp] = useState(data.acf.line_up);

  useEffect(() => {
    setParentData({
      acf: {
        line_up: lineUp,
      },
    });
  }, [setParentData, lineUp]);
  return (
    <>
      <MultilineText
        disabled={disabled}
        label="Line Up"
        value={lineUp}
        setValue={setLineUp}
      />
    </>
  );
}

const empty = {
  id: 0,
  acf: {
    line_up: "",
  },
};

// @ts-ignore
const x = withSheetLoader(
  "date_night_sheet_lup",
  empty,
  withSheetSaver<DClub_NightLineUp, SheetNightLineUpProps>(
    "date_night_sheet_lup",
    SheetNightLineUp
  )
);
// @ts-ignore
const PanelSheetNightLineUp = x as React.ComponentType<
  Partial<SheetNightLineUpProps>
>;
export { PanelSheetNightLineUp };
