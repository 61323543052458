import { useContext, useEffect, useState } from "react";
import {
  DClub_API_Date,
  DClub_Artist,
  DClub_Date,
  DClub_Place_Date,
  DClub_Style_Date,
} from "../../apiTypes";
import getAll from "../../utils/getAll";
import { ApiContext } from "../account/authenticatedAPI";
import useArtistsSlice from "../artists/useArtists";
import useDatePlacesSlice from "../datePlaces/useDatePlacesSlice";
import useDateStylesSlice from "../dateStyles/useDateStylesSlice";
import { ORDER } from "./Panel";

function useDates(
  params: { [k: string]: string },
  load = true
): [boolean, DClub_Date[]] {
  const api = useContext(ApiContext);
  const [rawDates, setRawDates] = useState<DClub_API_Date[]>([]);
  const artistsIds = rawDates
    .filter((date) => Array.isArray(date.acf.tracking_artists))
    .flatMap((date) =>
      date.acf.tracking_artists.flatMap((track) => track.artiste)
    );
  const [artistsLoaded, artists] = useArtistsSlice(artistsIds);
  const [datePlacesLoaded, datePlaces] = useDatePlacesSlice();
  const [styleDateLoaded, styleDate] = useDateStylesSlice();
  const [loaded, setLoaded] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    if (
      loaded !== JSON.stringify(params) &&
      loading !== JSON.stringify(params) &&
      load
    ) {
      setLoading(JSON.stringify(params));
      api.then((api) => {
        getAll(
          Object.entries(params)
            .reduce(
              (prev, current) => prev.param(current[0], current[1]),
              api.date()
            )
            .perPage(100)
            .status(["publish", "draft"])
        ).then((res: DClub_API_Date[]) => {
          setRawDates(res);
          setLoaded(JSON.stringify(params));
          setLoading("");
        });
      });
    }
  }, [
    api,
    loaded,
    loading,
    params,
    artistsLoaded,
    artists,
    datePlaces,
    datePlacesLoaded,
    load,
  ]);

  if (!artistsLoaded || !datePlacesLoaded || !styleDateLoaded) {
    return [false, []];
  }
  // @ts-ignore
  const cleanedDates: DClub_Date[] = rawDates.map(
    (date) =>
      ({
        ...date,
        date_style: date.date_style
          ? (date.date_style
              .map((date_style_id) =>
                styleDate.find((style) => style.id === date_style_id)
              )
              .filter((p) => p !== undefined) as DClub_Style_Date[])
          : [],
        date_place: date.date_place
          .map((date_place_id) => {
            const place = datePlaces.find(
              (place) => place.id === date_place_id
            );
            return place;
          })
          .filter((p) => p !== undefined) as DClub_Place_Date[],
        acf: {
          ...date.acf,
          tracking_artists: date.acf.tracking_artists?.map(
            ({ tracking, artiste }) => ({
              tracking: tracking,
              artiste: artists.find((a) => a.id === artiste) as DClub_Artist,
            })
          ),
        },
      } as DClub_Date)
  );
  const order = Object.values(ORDER) as string[];
  cleanedDates.sort((a, b) => {
    return (
      order.indexOf(a.date_place[0].slug) - order.indexOf(b.date_place[0].slug)
    );
  });
  return [loaded === JSON.stringify(params), cleanedDates];
}

export default useDates;
