import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { filterViewAccess, Views } from "./PanelSheets";
import useRole from "../account/useRole";

function ViewSelector({
  value,
  setValue,
}: {
  value: Views;
  setValue: (newValue: Views) => void;
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectView = (view: Views) => () => {
    handleClose();
    setValue(view);
  };
  const [rolesLoaded, roles] = useRole();

  return (
    <>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Fiche: {value}
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {rolesLoaded &&
          roles &&
          filterViewAccess(roles).map((view) => (
            <MenuItem key={view} onClick={selectView(view)}>
              {view}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}

export default ViewSelector;
