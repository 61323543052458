import { useContext } from "react";
import { ApiContext } from "../account/authenticatedAPI";

import type { DClub_Style_Date } from "../../apiTypes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setIsLoading, setStyles } from "./dateStylesSlice";
import getAll from "../../utils/getAll";

const useDateStylesSlice = (): [boolean, DClub_Style_Date[]] => {
  const api = useContext(ApiContext);
  const loaded = useAppSelector((state) => state.dateStyles.loaded);
  const isLoading = useAppSelector((state) => state.dateStyles.isLoading);
  const dispatch = useAppDispatch();

  if (!loaded && !isLoading) {
    dispatch(setIsLoading());
    api.then((api) =>
      getAll(api.date_style().perPage(100)).then(
        (place_date: DClub_Style_Date[]) => {
          dispatch(setStyles(place_date));
        }
      )
    );
  }

  return [loaded, useAppSelector((state) => state.dateStyles.styles)];
};

export default useDateStylesSlice;
