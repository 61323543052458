import { useEffect, useState } from "react";
import MultilineText from "../fields/MultilineText";
import withSheetLoader, { InjectedLoadedSheetProps } from "./SheetLoader";
import { withSheetSaver } from "./SheetSaver";
import { WP_Post } from "../../../apiTypes";

interface DClub_Security extends WP_Post {
  acf: {
    security_staff_present: string;
    security_remarque_generale: string;
    security_type_de_clientele: string;
    security_remarque_client: string;
    security_interventions: string;
    security_infos_victimes_agresseurs: string;
  };
}
export type { DClub_Security };
interface SheetSecurityProps extends InjectedLoadedSheetProps<DClub_Security> {}

function SheetSecurity({ data, setParentData }: SheetSecurityProps) {
  const [staff, setStaff] = useState(data.acf.security_staff_present);
  const [remarque, setRemarque] = useState(data.acf.security_remarque_generale);
  const [type, setType] = useState(data.acf.security_type_de_clientele);
  const [remarqueClient, setRemarqueClient] = useState(
    data.acf.security_remarque_client
  );
  const [interventions, setInterventions] = useState(
    data.acf.security_interventions
  );
  const [infoVictAgr, setInfoVictAgr] = useState(
    data.acf.security_infos_victimes_agresseurs
  );

  useEffect(() => {
    setParentData({
      acf: {
        security_staff_present: staff,
        security_remarque_generale: remarque,
        security_type_de_clientele: type,
        security_remarque_client: remarqueClient,
        security_interventions: interventions,
        security_infos_victimes_agresseurs: infoVictAgr,
      },
    });
  }, [
    setParentData,
    staff,
    remarque,
    type,
    remarqueClient,
    interventions,
    infoVictAgr,
  ]);
  return (
    <>
      <MultilineText label="Staff Présent" value={staff} setValue={setStaff} />
      <MultilineText
        label="Remarque générale"
        value={remarque}
        setValue={setRemarque}
      />
      <MultilineText
        label="Type de clientèle"
        value={type}
        setValue={setType}
      />
      <MultilineText
        label="Remarque client"
        value={remarqueClient}
        setValue={setRemarqueClient}
      />
      <MultilineText
        label="Interventions"
        value={interventions}
        setValue={setInterventions}
      />
      <MultilineText
        label="Info victimes / agresseurs"
        value={infoVictAgr}
        setValue={setInfoVictAgr}
      />
    </>
  );
}

const empty = {
  id: 0,
  acf: {
    security_staff_present: "",
    security_remarque_generale: "",
    security_type_de_clientele: "",
    security_remarque_client: "",
    security_interventions: "",
    security_infos_victimes_agresseurs: "",
  },
};

// @ts-ignore
const x = withSheetLoader(
  "date_security_sheet",
  empty,
  withSheetSaver<DClub_Security, SheetSecurityProps>(
    "date_security_sheet",
    SheetSecurity
  )
);
// @ts-ignore
const PanelSheetSecurity = x as React.ComponentType<
  Partial<SheetSecurityProps>
>;
export { PanelSheetSecurity };
