import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "../features/account/accountSlice";
import datePlacesReducer from "../features/datePlaces/datePlacesSlice";
import dateStylesReducer from "../features/dateStyles/dateStylesSlice";
import artistsReducer from "../features/artists/artistsSlice";

const saveToLocalStorage = (state: any) => {
  try {
    localStorage.setItem("state", JSON.stringify(state.account));
  } catch (e) {
    console.error(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem("state");
    return stateStr ? { account: JSON.parse(stateStr) } : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedStore = loadFromLocalStorage();

const store = configureStore({
  reducer: {
    account: accountReducer,
    datePlaces: datePlacesReducer,
    dateStyles: dateStylesReducer,
    artists: artistsReducer,
  },
  preloadedState: persistedStore,
});

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
