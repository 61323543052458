import { Subtract } from "utility-types";
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import TaxonomyInput from "../fields/TaxonomyInput";
import { InjectedSheetDataProps, withSheetSaver } from "./SheetSaver";
import type {
  DClub_Age,
  DClub_Date,
  DClub_Mise_En_Avant,
  DClub_Place_Date,
  DClub_Style_Date,
} from "../../../apiTypes";
import SimpleText from "../fields/SimpleText";
import MultilineText from "../fields/MultilineText";
import ArtistsTracking from "../../artists/ArtistsTracking";
import EditArtistsTracking from "../fields/ArtistsTracking";
import FileUpload from "../fields/FileUpload";
import SimpleSelect from "../fields/SimpleSelect";
import { Edit } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TimePicker } from "@mui/lab";
import { format, parse } from "date-fns";

interface SheetDateProps extends InjectedSheetDataProps<DClub_Date> {
  datePlaces: DClub_Place_Date[];
  dateStyles: DClub_Style_Date[];
  data: DClub_Date;
}
const MiseEnAvantNames: { [key in DClub_Mise_En_Avant]: string } = {
  front_linup: "Le Line Up",
  front_date: "La soirée",
};
const AgeNames: { [key in DClub_Age]: string } = {
  "16": "+16 ans",
  "18": "+18 ans",
  "25": "+25 ans",
};
export { AgeNames };
function SheetDate({
  data,
  setParentData,
  datePlaces,
  dateStyles,
}: SheetDateProps) {
  const [trackinArtistsOpen, setTrackingArtistsOpen] = useState(false);

  const [title, setTitle] = useState(data.title.rendered);
  const [content, setContent] = useState(data.acf.date_remarque_generale);
  const [option, setOption] = useState(data.acf.date_options);
  const [places, setPlaces] = useState(data.date_place);
  const [styles, setStyles] = useState(data.date_style ? data.date_style : []);
  const [artists, setArtists] = useState(
    data.acf.tracking_artists ? data.acf.tracking_artists : [],
  );
  const [descSoiree, setDescSoiree] = useState(
    data.acf.date_description_soiree,
  );
  const [link, setLink] = useState(data.acf.date_lien_ticket);
  const [doors, setDoors] = useState(data.acf.date_ouverture_des_portes);
  const [avant, setAvant] = useState(data.acf.mise_en_avant);
  const [age, setAge] = useState(data.acf.date_age);
  const [postStatus, setPostStatus] = useState(data.status);
  const [featuredImage, setFeaturedImage] = useState(data.featured_media);
  const [comingSoon, setComingSoon] = useState(
    data.acf.coming_soon ? data.acf.coming_soon : false,
  );
  const [sliderTv, setSliderTv] = useState(
    data.acf.slider_tv ? data.acf.slider_tv : false,
  );
  const [tableComplete, setTableComplete] = useState(
    data.acf.table_complete ? data.acf.table_complete : false,
  );
  const [noTable, setNoTable] = useState(
    data.acf.no_tables ? data.acf.no_tables : false,
  );
  useEffect(() => {
    setParentData({
      title: title,
      date_place: places.map((value) => value.id),
      date_style: styles.map((value) => value.id),
      status: postStatus,
      featured_media: featuredImage,
      acf: {
        date_remarque_generale: content,
        date_options: option,
        date_description_soiree: descSoiree,
        date_lien_ticket: link,
        date_ouverture_des_portes: doors,
        date_age: age,
        mise_en_avant: avant,
        coming_soon: comingSoon,
        slider_tv: sliderTv,
        table_complete: tableComplete,
        no_tables: noTable,
        tracking_artists: artists
          .filter(({ artiste }) => artiste !== undefined)
          // @ts-ignore
          .map(({ artiste, ...rest }) => ({ ...rest, artiste: artiste.id })),
      },
    });
  }, [
    title,
    content,
    places,
    postStatus,
    option,
    descSoiree,
    link,
    doors,
    artists,
    styles,
    setParentData,
    featuredImage,
    age,
    avant,
    comingSoon,
    sliderTv,
    tableComplete,
    noTable,
  ]);
  return (
    <>
      <TaxonomyInput
        taxonomies={datePlaces}
        onChange={setPlaces}
        value={places}
        label="Lieu"
      />
      <TaxonomyInput
        taxonomies={dateStyles}
        onChange={setStyles}
        value={styles}
        label="Style"
      />
      <SimpleText label="Titre" value={title} setValue={setTitle} />
      <ArtistsTracking
        header={
          <TableHead component="div">
            <TableRow component="div">
              <TableCell component="div">Artiste</TableCell>
              <TableCell component="div" align="right">
                <IconButton onClick={() => setTrackingArtistsOpen(true)}>
                  <Edit />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
        }
        value={artists}
      />
      <Dialog
        open={trackinArtistsOpen}
        fullWidth
        maxWidth="md"
        onClose={() => setTrackingArtistsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Tracking Artistes"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <EditArtistsTracking value={artists} setValue={setArtists} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setTrackingArtistsOpen(false)}
            color="primary"
            autoFocus
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>

      <SimpleSelect
        options={MiseEnAvantNames}
        value={avant}
        setValue={setAvant}
        label="Mise en avant"
      />
      <MultilineText
        label="Description soirée"
        value={descSoiree}
        setValue={setDescSoiree}
      />
      <SimpleText
        label="Lien ticket"
        value={link}
        setValue={setLink}
        type="url"
      />
      <TimePicker
        label="Ouverture des portes"
        clearable
        value={
          doors && doors.length > 0
            ? parse(doors, "HH:mm:ss", new Date())
            : null
        }
        onChange={(newValue: Date | null) => {
          try {
            setDoors(newValue ? format(newValue, "HH:mm:ss") : undefined);
          } catch {
            /* Don't change the input */
          }
        }}
        renderInput={(params) => <TextField variant="filled" {...params} />}
      />
      <SimpleSelect
        label="Âge"
        options={AgeNames}
        value={age}
        setValue={setAge}
      />
      <MultilineText
        label="Remarque générale"
        value={content}
        setValue={setContent}
      />
      <MultilineText label="Options" value={option} setValue={setOption} />
      <FormControlLabel
        control={
          <Switch
            checked={comingSoon}
            onChange={(event) => setComingSoon(event.target.checked)}
          />
        }
        label="Coming Soon"
      />
      <FormControlLabel
        control={
          <Switch
            checked={sliderTv}
            onChange={(event) => setSliderTv(event.target.checked)}
          />
        }
        label="Affichage slider TV"
      />
      <FormControlLabel
        control={
          <Switch
            checked={tableComplete}
            onChange={(event) => setTableComplete(event.target.checked)}
          />
        }
        label="Table indisponnible"
      />
      <FormControlLabel
        control={
          <Switch
            checked={noTable}
            onChange={(event) => setNoTable(event.target.checked)}
          />
        }
        label="Pas de table"
      />
      <FileUpload
        id={featuredImage}
        image
        onChange={setFeaturedImage}
        label="Modifier l'image"
        acceptedFiles={["image/*"]}
      />
      <Button
        variant="contained"
        color={postStatus === "publish" ? "success" : "error"}
        onClick={useCallback(() => {
          if (postStatus === "publish") setPostStatus("draft");
          else setPostStatus("publish");
        }, [postStatus, setPostStatus])}
      >
        {postStatus === "draft" ? "Publier" : "Dé-publier"}
      </Button>
    </>
  );
}

const x = withSheetSaver<DClub_Date, SheetDateProps>("date", SheetDate);
const PanelSheetDate = x as React.ComponentType<
  Subtract<SheetDateProps, InjectedSheetDataProps<DClub_Date>>
>;
export { PanelSheetDate };
