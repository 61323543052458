import { Theme } from "@mui/material";
import { useTheme } from "@mui/styles";
import styled, { keyframes } from "styled-components";
import Logo from "./Logo";

const LoadingContainer = styled.div`
  height: 100%;
  //position: relative;
`;
const LogoContainer = styled.div`
  width: 7em;
  height: 7em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #f23b3b;
  border-radius: 100%;
`;
const size = keyframes`
  from {
    transform: scale(1);
    opacity: 1
  }

  to {
    transform: scale(3);
    opacity: 0
  }
`;
const LogoCircle = styled.div`
  background: radial-gradient(#f23b3b, transparent);
  border-radius: 100%;
  animation: ${size} 1.5s ease-in-out infinite;
  height: 100%;
`;
const Loading: React.FC<{ className?: string }> = ({ className }) => {
  const theme = useTheme<Theme>();
  return (
    <LoadingContainer className={className}>
      <LogoContainer>
        <LogoCircle />
      </LogoContainer>
      <LogoContainer>
        <Logo
          innerColor={theme.palette.primary.main}
          outerColor={theme.palette.primary.contrastText}
        />
      </LogoContainer>
    </LoadingContainer>
  );
};
export default Loading;
