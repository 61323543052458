import { Redirect, Route, Switch } from "react-router-dom";
import Calendar from "./features/calendar/Calendar";
import Panel from "./features/dates/Panel";

function AuthenticatedApp() {
  return (
    <Switch>
      <Route path="/edit/:date?" children={<Panel />} />
      <Route path="/calendar/:date?" children={<Calendar />} />
      <Route exact path="/">
        <Redirect to="/calendar" />
      </Route>
    </Switch>
  );
}

export default AuthenticatedApp;
