import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { DClub_Style_Date } from "../../apiTypes";

interface DateStylesState {
  styles: DClub_Style_Date[];
  loaded: boolean;
  isLoading: boolean;
}

const initialState = {
  styles: [],
  loaded: false,
  isLoading: false,
} as DateStylesState;

const dateStylesSlice = createSlice({
  name: "dateStyles",
  initialState,
  reducers: {
    setStyles(_, action: PayloadAction<DClub_Style_Date[]>) {
      return { styles: action.payload, loaded: true, isLoading: false };
    },
    setIsLoading(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const { setStyles, setIsLoading, reset } = dateStylesSlice.actions;
export default dateStylesSlice.reducer;
