import { IconButton, TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { addDays } from "date-fns";
import { useEffect, useRef, useState } from "react";

interface DateSlectorProps {
  onChange: (newValue: Date) => void;
  disabled: boolean;
  value: Date;
}
function DateSlector({ onChange, disabled, value }: DateSlectorProps) {
  const [date, setDate] = useState(value);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) initialRender.current = false;
    else onChange(date);
  }, [onChange, date]);

  return (
    <>
      <IconButton
        onClick={() =>
          setDate((date) => {
            return addDays(date, -1);
          })
        }
        disabled={disabled}
        size="large"
      >
        <NavigateBefore />
      </IconButton>
      <DatePicker
        value={date}
        onChange={(newV) => {
          if (newV) setDate(newV);
        }}
        renderInput={(params) => (
          <TextField sx={{ verticalAlign: "middle" }} {...params} />
        )}
        disabled={disabled}
      />
      <IconButton
        onClick={() =>
          setDate((date) => {
            return addDays(date, 1);
          })
        }
        disabled={disabled}
        size="large"
      >
        <NavigateNext />
      </IconButton>
    </>
  );
}

export default DateSlector;
