import { createSlice } from "@reduxjs/toolkit";

type DClub_User_Role =
  | "administrator"
  | "dclub_admin"
  | "dclub_secretaire"
  | "dclub_organisteur"
  | "dclub_technician"
  | "dclub_security";

export type { DClub_User_Role };
export const accountSlice = createSlice({
  name: "account",
  initialState: {
    user_login: "",
    password: "",
    logged_in: false,
    role: undefined,
    roleIsLoading: false,
  },
  reducers: {
    logOff: (state) => {
      state.user_login = "";
      state.password = "";
      state.logged_in = false;
      state.role = undefined;
      state.roleIsLoading = false;
    },
    logIn: (state, action) => {
      state.user_login = action.payload.user_login;
      state.password = action.payload.password;
      state.logged_in = true;
    },
    setRole: (state, action) => ({
      ...state,
      role: action.payload,
      roleIsLoading: false,
    }),
    setRoleIsLoading: (state) => ({
      ...state,
      roleIsLoading: true,
    }),
  },
});

export const { logOff, logIn, setRole, setRoleIsLoading } =
  accountSlice.actions;

export default accountSlice.reducer;
