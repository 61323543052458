import { WPRequest } from "wpapi";

function getAll(request: WPRequest): Promise<any[]> {
  return request.then(function (response) {
    if (!response._paging || !response._paging.next) {
      return response;
    }

    const remainingPages = Array(response._paging.totalPages - 1);
    for (let i = 0; i < remainingPages.length; i++) {
      remainingPages[i] = request.page(i + 2).then((response) => {
        return response;
      });
    }
    // Request the next page and return both responses as one collection
    return Promise.all([response, ...remainingPages]).then(function (
      responses: any[]
    ) {
      const items = [].concat(...Object.values(responses));
      console.log(
        `Loaded ${items.length} ${request._path[0]} out of ${response._paging.total}`
      );
      return items;
    });
  });
}

export default getAll;
