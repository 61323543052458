import { useContext } from "react";
import { ApiContext } from "../account/authenticatedAPI";

import type { DClub_Place_Date } from "../../apiTypes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setIsLoading, setPlaces } from "./datePlacesSlice";

const useDatePlacesSlice = (): [boolean, DClub_Place_Date[]] => {
  const api = useContext(ApiContext);
  const loaded = useAppSelector((state) => state.datePlaces.loaded);
  const isLoading = useAppSelector((state) => state.datePlaces.isLoading);
  const dispatch = useAppDispatch();

  if (!loaded && !isLoading) {
    dispatch(setIsLoading());
    api.then((api) =>
      api.date_place().then((place_date: DClub_Place_Date[]) => {
        dispatch(setPlaces(place_date));
      })
    );
  }

  return [loaded, useAppSelector((state) => state.datePlaces.places)];
};

export default useDatePlacesSlice;
