import { useContext } from "react";
import { ApiContext } from "../account/authenticatedAPI";

import type { DClub_Artist } from "../../apiTypes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setIsLoading, setAllArtists } from "./artistsSlice";
import getAll from "../../utils/getAll";
import { decode } from "he";

const useAllArtistsSlice = (): [boolean, DClub_Artist[]] => {
  const api = useContext(ApiContext);
  const loaded = useAppSelector((state) => state.artists.loaded);
  const isLoading = useAppSelector((state) => state.artists.isLoading);
  const dispatch = useAppDispatch();

  if (!loaded && !isLoading) {
    dispatch(setIsLoading());
    api.then((api) =>
      getAll(api.artist().perPage(100)).then((artists: DClub_Artist[]) => {
        dispatch(
          setAllArtists(
            artists.map((artist) => ({
              ...artist,
              title: { rendered: decode(artist.title.rendered) },
            }))
          )
        );
      })
    );
  }

  return [
    loaded,
    useAppSelector((state) => Object.values(state.artists.artists)),
  ];
};

export default useAllArtistsSlice;
