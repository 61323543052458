import { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { useDispatch } from "react-redux";
import { logIn } from "./features/account/accountSlice";
import { useAppSelector } from "./app/hooks";
import { Button } from "@mui/material";
import getWpUrl from "./utils/getWpUrl";

const url = new URL(document.URL);
const { hostname, protocol, port } = url;
const baseURL =
  process.env.PUBLIC_URL && process.env.PUBLIC_URL.length > 0
    ? process.env.PUBLIC_URL
    : `${protocol}//${hostname}:${port}`;
const SUCCESS_URL = `${baseURL}/login/success`;
const REJECT_URL = `${baseURL}/login/reject`;

async function detectWP(): Promise<string> {
  return fetch(getWpUrl() + "?rest_route=/")
    .then((res) => res.json())
    .then((data) => {
      if ("application-passwords" in data.authentication) {
        const url = new URL(
          data.authentication["application-passwords"].endpoints.authorization
        );
        url.searchParams.append("app_name", "D! Club Gestion");
        url.searchParams.append(
          "app_id",
          "8aeb320f-d7e1-4290-90d1-ba2db654b4c9"
        );
        url.searchParams.append("success_url", SUCCESS_URL);
        url.searchParams.append("reject_url", REJECT_URL);
        return url.toString();
      } else throw new Error("application passwrod not enabled");
    });
}
function Login() {
  const [loginURL, setLoginURL] = useState<string>("");

  useEffect(() => {
    detectWP().then((r) => setLoginURL(r));
  }, []);

  const loggedIn = useAppSelector((state) => state.account.logged_in);
  const match = useRouteMatch();
  return (
    <div>
      {loggedIn && <Redirect to="/" />}
      <Switch>
        <Route path={[`${match.path}/reject`]}>
          <div>Error login</div>
        </Route>
      </Switch>
      <Switch>
        <Route path={`${match.path}/success`}>
          <Success />
        </Route>
        <Route path={[`${match.path}/reject`, `${match.path}`]}>
          {loginURL !== "" && (
            <Button
              className="App-link"
              href={loginURL}
              rel="noopener noreferrer"
              variant="contained"
            >
              Connexion
            </Button>
          )}
        </Route>
      </Switch>
    </div>
  );
}
function Success() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("user_login");
  const token = searchParams.get("password");

  const dispatch = useDispatch();
  dispatch(logIn({ user_login: userId, password: token }));
  return (
    <div>
      Success {userId}, {token}
    </div>
  );
}

export default Login;
