import { createContext, useEffect, useState } from "react";
import WPAPI from "wpapi";
import { useAppSelector } from "../../app/hooks";
import getWpUrl from "../../utils/getWpUrl";

// Load the route statically so we can remove the buggy ones
// and avoid unnecessary requests
const wpJson = require("../../wp.json");
const staticAPI = new WPAPI({
  endpoint: getWpUrl() + "wp-json/",
  routes: wpJson.routes,
});
function useAPI(): [Promise<WPAPI>, boolean] {
  const account = useAppSelector((state) => state.account);
  const [api, setApi] = useState(() => Promise.resolve(staticAPI));
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (account.logged_in)
      setApi((api) =>
        api.then((x) => {
          setLoggedIn(true);
          return x.auth({
            username: account.user_login,
            password: account.password,
          });
        })
      );
  }, [account.logged_in, account.password, account.user_login]);
  return [api, loggedIn];
}
export default useAPI;
const ApiContext = createContext<Promise<WPAPI>>(Promise.resolve(staticAPI));
export { ApiContext };
