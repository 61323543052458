import { IconButton, TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { addMonths } from "date-fns";
import { useEffect, useRef, useState } from "react";

interface MonthSlectorProps {
  onChange: (newValue: Date) => void;
  disabled?: boolean;
  value: Date;
}
function MonthSlector({
  onChange,
  disabled = false,
  value,
}: MonthSlectorProps) {
  const [date, setMonth] = useState(value);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) initialRender.current = false;
    else onChange(date);
  }, [onChange, date]);

  return (
    <>
      <IconButton
        onClick={() =>
          setMonth((date) => {
            return addMonths(date, -1);
          })
        }
        disabled={disabled}
        size="large"
      >
        <NavigateBefore />
      </IconButton>
      <DatePicker
        views={["year", "month"]}
        value={date}
        onChange={(newV) => {
          if (newV) setMonth(newV);
        }}
        renderInput={(params) => (
          <TextField sx={{ verticalAlign: "middle" }} {...params} />
        )}
        disabled={disabled}
      />
      <IconButton
        onClick={() =>
          setMonth((date) => {
            return addMonths(date, 1);
          })
        }
        disabled={disabled}
        size="large"
      >
        <NavigateNext />
      </IconButton>
    </>
  );
}

export default MonthSlector;
