import { FilledTextFieldProps, TextField } from "@mui/material";
import { useCallback } from "react";

interface SimpleTextProps<T> extends Omit<FilledTextFieldProps, "variant"> {
  value: T;
  setValue?: (newValue: T) => void;
}
export type { SimpleTextProps };

function SimpleText<T>({ value, setValue, ...props }: SimpleTextProps<T>) {
  const callback = useCallback(
    (event) => {
      if (setValue) setValue(event.target.value);
    },
    [setValue]
  );
  return (
    <TextField {...props} variant="filled" value={value} onChange={callback} />
  );
}

export default SimpleText;
