import { Autocomplete, TextField } from "@mui/material";
import { useCallback } from "react";
import { WP_Taxonomy } from "../../../apiTypes";

interface TaxonomyInputProps {
  label: string;
  taxonomies: WP_Taxonomy[];
  onChange?: (newValue: WP_Taxonomy[]) => void;
  value: WP_Taxonomy[] | undefined;
  disabled?: boolean;
}
function TaxonomyInput({
  label,
  taxonomies,
  onChange,
  value,
  disabled = false,
}: TaxonomyInputProps) {
  const callback = useCallback(
    (_, newValue) => {
      if (newValue.length >= 1 && onChange) onChange(newValue);
    },
    [onChange]
  );
  return (
    <Autocomplete
      multiple
      disabled={disabled}
      id="tags-standard"
      options={taxonomies}
      getOptionLabel={useCallback((option) => option.name, [])}
      value={value}
      onChange={callback}
      renderInput={useCallback(
        (params) => (
          <TextField {...params} variant="filled" label={label} />
        ),
        [label]
      )}
    />
  );
}

export default TaxonomyInput;
