import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
interface SelectProps<T extends string> {
  options: { [key in T]?: string };
  value?: T;
  setValue: (newValue?: T) => void;
  label: string;
  disabled?: boolean;
}

function SimpleSelect<T extends string>({
  options,
  value,
  setValue,
  label,
  disabled = false,
}: SelectProps<T>) {
  return (
    <FormControl variant="filled">
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disabled}
        style={{ textAlign: "left" }}
        value={value}
        onChange={(event) => setValue(event.target.value as T)}
      >
        <MenuItem value={undefined}>
          <em>Vide</em>
        </MenuItem>
        {(Object.entries(options) as [T, string][]).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
export default SimpleSelect;
