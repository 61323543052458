import { useEffect, useState } from "react";
import MultilineText from "../fields/MultilineText";
import SimpleText from "../fields/SimpleText";
import withSheetLoader, { InjectedLoadedSheetProps } from "./SheetLoader";
import { withSheetSaver } from "./SheetSaver";
import { WP_Post } from "../../../apiTypes";

interface DClub_Night extends WP_Post {
  acf: {
    soiree_estimation_frequentation: string;
    soiree_organisation_partenaire: string;
    prix: string;
    soiree_contact: string;
    soiree_cachets: string;
    soiree_bons_boissons: string;
    soiree_voyage_hotel_catering_artiste: string;
  };
}
export type { DClub_Night };
interface SheetNightProps extends InjectedLoadedSheetProps<DClub_Night> {}

function SheetNight({ data, setParentData }: SheetNightProps) {
  const [freq, setFreq] = useState(data.acf.soiree_estimation_frequentation);
  const [organisation_partenaire, set_organisation_partenaire] = useState(
    data.acf.soiree_organisation_partenaire
  );
  const [prix, setPrix] = useState(data.acf.prix);
  const [contact, set_contact] = useState(data.acf.soiree_contact);
  const [cachets, set_cachets] = useState(data.acf.soiree_cachets);
  const [bons_boissons, set_bons_boissons] = useState(
    data.acf.soiree_bons_boissons
  );
  const [voyage_hotel_catering_artiste, set_voyage_hotel_catering_artiste] =
    useState(data.acf.soiree_voyage_hotel_catering_artiste);

  useEffect(() => {
    setParentData({
      acf: {
        soiree_estimation_frequentation: freq,
        soiree_organisation_partenaire: organisation_partenaire,
        prix: prix,
        soiree_contact: contact,
        soiree_cachets: cachets,
        soiree_bons_boissons: bons_boissons,
        soiree_voyage_hotel_catering_artiste: voyage_hotel_catering_artiste,
      },
    });
  }, [
    setParentData,
    freq,
    organisation_partenaire,
    prix,
    contact,
    cachets,
    bons_boissons,
    voyage_hotel_catering_artiste,
  ]);
  return (
    <>
      <MultilineText
        label="Estimation de fréquentation"
        value={freq}
        setValue={setFreq}
      />
      <MultilineText
        label="Organisation partenaire"
        value={organisation_partenaire}
        setValue={set_organisation_partenaire}
      />
      <SimpleText label="Prix" value={prix} setValue={setPrix} />
      <MultilineText label="Contact" value={contact} setValue={set_contact} />
      <MultilineText label="Cachets" value={cachets} setValue={set_cachets} />
      <MultilineText
        label="Bons boissons"
        value={bons_boissons}
        setValue={set_bons_boissons}
      />
      <MultilineText
        label="Voyage/hôtel/Catering Artiste"
        value={voyage_hotel_catering_artiste}
        setValue={set_voyage_hotel_catering_artiste}
      />
    </>
  );
}

const empty = {
  id: 0,
  acf: {
    soiree_estimation_frequentation: "",
    soiree_organisation_partenaire: "",
    prix: "",
    soiree_contact: "",
    soiree_cachets: "",
    soiree_bons_boissons: "",
    soiree_voyage_hotel_catering_artiste: "",
  },
};

// @ts-ignore
const x = withSheetLoader(
  "date_night_sheet",
  empty,
  withSheetSaver<DClub_Night, SheetNightProps>("date_night_sheet", SheetNight)
);
// @ts-ignore
const PanelSheetNight = x as React.ComponentType<Partial<SheetNightProps>>;
export { PanelSheetNight };
