import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useAutosave } from "react-autosave";
import { Subtract } from "utility-types";
import { ApiContext } from "../../account/authenticatedAPI";
import type { WP_Post } from "../../../apiTypes";

// TODO maybe check why it does not compile without S
/*eslint-disable*/
interface InjectedSheetDataProps<S> {
  setParentData: any;
  dateId: number;
  newDate: boolean;
}
interface Sheet<S> {
  data: S;
}
interface SheetSaverProps<S> extends Sheet<S> {
  setParentDirty: (dirty: boolean) => void;
  newDate: boolean;
  insert: () => void;
  dateId: number;
}
function withSheetSaver<S extends WP_Post, P extends InjectedSheetDataProps<S>>(
  postType: string,
  Component: React.ComponentType<P>
) {
  function SheetSaver(
    props: SheetSaverProps<S> & Subtract<P, InjectedSheetDataProps<S>>
  ) {
    const childProp = props as Subtract<P, InjectedSheetDataProps<S>>;
    const childAllProp: P = {
      ...childProp,
    } as P;
    const { setParentDirty, dateId, insert } = props;
    const date: S = props.data;
    const api = useContext(ApiContext);
    const initialRender = useRef(true);
    const [data, setData] = useState<any>({});
    // dirty = true <=> data is modified and not saved
    const [dirty, setDirty] = useState(false);
    const [waitingForId, setWaitingForId] = useState(false);
    const [id, setId] = useState(date.id);
    const prevId = useRef(date.id);

    useEffect(() => {
      prevId.current = id;
    }, [id]);

    useEffect(() => setParentDirty(dirty), [dirty, setParentDirty]);

    useEffect(() => {
      if (initialRender.current) {
        initialRender.current = false;
        return;
      }
    });

    const save = useCallback(
      (data) => {
        if (initialRender.current) {
          return;
        } else {
          if (id <= 0) {
            if (dateId <= 0) {
              insert();
              setWaitingForId(true);
            } else {
              /*if (postType !== 'date')
              api.then(api => {
                api[postType].param('parent', dateId).then((res: any) => setId(res.id))
              })
            else*/
              setId(dateId);
            }
          } else {
            api
              .then((api) => {
                return api[postType]()
                  .id(id)
                  .update({ ...data, id });
              })
              .then((res: any) => {
                setDirty(false);
              });
          }
        }
      },
      [api, dateId, id, insert]
    );

    useAutosave({
      data: data,
      onSave: save,
    });
    return (
      <Component
        {...childAllProp}
        dateId={dateId}
        //date={date}
        newDate
        setParentData={useCallback(
          (data: any) => {
            // we check that the previous id isn't 0 otherwise it is updated twice
            if (prevId.current === 0 && id !== 0) return;
            if (initialRender.current) initialRender.current = false;
            else {
              setDirty(true);
              setData({ id, ...data });
            }
          },
          [id, prevId]
        )}
      />
    );
  }

  return SheetSaver;
}
export { withSheetSaver };
export type { SheetSaverProps, InjectedSheetDataProps };
