import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useCallback } from "react";
import type {
  DClub_Artists_Tracking_Stage,
  DClub_Artists_Tracking,
} from "../../../apiTypes";
import useAllArtistsSlice from "../../artists/useAllArtists";
import clone from "clone";
import {
  Add,
  Remove,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import React from "react";

interface ArtistsTrackingProps {
  value: DClub_Artists_Tracking[];
  setValue: (newValue: DClub_Artists_Tracking[]) => void;
  disabled?: boolean;
}

function ArtistsTracking({
  value,
  setValue,
  disabled = false,
}: ArtistsTrackingProps) {
  const [artistsLoaded, artists] = useAllArtistsSlice();

  const addRow = useCallback(
    () => setValue([{ artiste: undefined, tracking: [] }, ...value]),
    [setValue, value]
  );

  const TrackingCheckbox = ({
    tracking,
    index,
    name,
  }: {
    tracking: DClub_Artists_Tracking_Stage[];
    index: number;
    name: DClub_Artists_Tracking_Stage;
  }) => {
    return (
      <Checkbox
        checked={tracking.indexOf(name) > -1}
        onChange={(event) => {
          const newTracking = new Set(tracking);
          if (event.target.checked) newTracking.add(name);
          else newTracking.delete(name);

          const newValue = clone(value);
          newValue[index] = {
            ...value[index],
            tracking: Array.from(newTracking.values()),
          };
          setValue(newValue);
        }}
      />
    );
  };
  return (
    <TableContainer>
      <Table aria-label="artisits table" size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Artiste</TableCell>
            <TableCell align="center">Contrat</TableCell>
            <TableCell align="center">Paiement</TableCell>
            <TableCell align="center">Vol</TableCell>
            <TableCell align="center">Hôtel</TableCell>
            <TableCell align="center">Bio</TableCell>
            <TableCell align="center">
              <IconButton onClick={addRow}>
                <Add />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {value.map(({ tracking, artiste }, index) => (
            <TableRow key={index}>
              <TableCell padding="checkbox">
                <Grid container direction="column">
                  <IconButton
                    onClick={() => {
                      if (index > 0) {
                        const newValue = clone(value);
                        const temp = newValue[index];
                        newValue[index] = newValue[index - 1];
                        newValue[index - 1] = temp;
                        setValue(newValue);
                      }
                    }}
                  >
                    <KeyboardArrowUp />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (index < value.length - 1) {
                        const newValue = clone(value);
                        const temp = newValue[index];
                        newValue[index] = newValue[index + 1];
                        newValue[index + 1] = temp;
                        setValue(newValue);
                      }
                    }}
                  >
                    <KeyboardArrowDown />
                  </IconButton>
                </Grid>
              </TableCell>
              <TableCell>
                <Autocomplete
                  disabled={disabled}
                  id="tags-standard"
                  options={artists}
                  getOptionLabel={(option) => option.title.rendered}
                  value={artiste === undefined ? null : artiste}
                  onChange={(_, newValue) => {
                    const newValue2 = clone(value);
                    newValue2[index] = {
                      ...value[index],
                      artiste: newValue ? newValue : undefined,
                    };
                    setValue(newValue2);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Artiste"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {!artistsLoaded ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </TableCell>
              <TableCell align="center" padding="checkbox">
                <TrackingCheckbox
                  name="contract"
                  index={index}
                  tracking={tracking}
                />
              </TableCell>
              <TableCell align="center" padding="checkbox">
                <TrackingCheckbox
                  name="pay"
                  index={index}
                  tracking={tracking}
                />
              </TableCell>
              <TableCell align="center" padding="checkbox">
                <TrackingCheckbox
                  name="flight"
                  index={index}
                  tracking={tracking}
                />
              </TableCell>
              <TableCell align="center" padding="checkbox">
                <TrackingCheckbox
                  name="hotel"
                  index={index}
                  tracking={tracking}
                />
              </TableCell>
              <TableCell align="center" padding="checkbox">
                <TrackingCheckbox
                  name="bio"
                  index={index}
                  tracking={tracking}
                />
              </TableCell>
              <TableCell align="center" padding="checkbox">
                <IconButton
                  onClick={() => {
                    const newValue = clone(value);
                    newValue.splice(index, 1);
                    setValue(newValue);
                  }}
                >
                  <Remove />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ArtistsTracking;
