import { useEffect, useState } from "react";
import MultilineText from "../fields/MultilineText";
import withSheetLoader, { InjectedLoadedSheetProps } from "./SheetLoader";
import { withSheetSaver } from "./SheetSaver";
import { WP_Post } from "../../../apiTypes";

interface DClub_Technical extends WP_Post {
  acf: {
    technical_staff_technique: string;
    technical_light_jockey: string;
    technical_horaire_staff: string;
    technical_frais: string;
    technical_transport: string;
  };
}
export type { DClub_Technical };
interface SheetTechnicalProps
  extends InjectedLoadedSheetProps<DClub_Technical> {}

function SheetTechnical({ data, setParentData }: SheetTechnicalProps) {
  const [staff_technique, set_staff_technique] = useState(
    data.acf.technical_staff_technique
  );
  const [light_jockey, set_light_jockey] = useState(
    data.acf.technical_light_jockey
  );
  const [horaire_staff, set_horaire_staff] = useState(
    data.acf.technical_horaire_staff
  );
  const [frais, set_frais] = useState(data.acf.technical_frais);
  const [transport, set_transport] = useState(data.acf.technical_transport);

  useEffect(() => {
    setParentData({
      acf: {
        technical_staff_technique: staff_technique,
        technical_light_jockey: light_jockey,
        technical_horaire_staff: horaire_staff,
        technical_frais: frais,
        technical_transport: transport,
      },
    });
  }, [
    setParentData,
    staff_technique,
    light_jockey,
    horaire_staff,
    frais,
    transport,
  ]);
  return (
    <>
      <MultilineText
        label="Staff téchnique"
        value={staff_technique}
        setValue={set_staff_technique}
      />
      <MultilineText
        label="Light Jockey"
        value={light_jockey}
        setValue={set_light_jockey}
      />
      <MultilineText
        label="Horraire Staff"
        value={horaire_staff}
        setValue={set_horaire_staff}
      />
      <MultilineText label="Frais" value={frais} setValue={set_frais} />
      <MultilineText
        label="Transport"
        value={transport}
        setValue={set_transport}
      />
    </>
  );
}

const empty = {
  id: 0,
  acf: {
    technical_staff_technique: "",
    technical_light_jockey: "",
    technical_horaire_staff: "",
    technical_frais: "",
    technical_transport: "",
  },
};

// @ts-ignore
const x = withSheetLoader(
  "date_technical_sheet",
  empty,
  withSheetSaver<DClub_Technical, SheetTechnicalProps>(
    "date_technical_sheet",
    SheetTechnical
  )
);
// @ts-ignore
const PanelSheetTechnical = x as React.ComponentType<
  Partial<SheetTechnicalProps>
>;
export { PanelSheetTechnical };
