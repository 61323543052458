import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import {
  Children,
  cloneElement,
  isValidElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { ApiContext } from "../../account/authenticatedAPI";
import { InjectedSheetDataProps, SheetSaverProps } from "./SheetSaver";
import type { DClub_Date, DClub_Place_Date, WP_Post } from "../../../apiTypes";

interface SheetProps<S> {
  date: DClub_Date;
  datePlaces: DClub_Place_Date[];
  newDate?: boolean;
  setParentDirty: (dirty: boolean) => void;
  children:
    | React.ReactElement<Partial<SheetSaverProps<S>>>
    | (React.ReactElement<Partial<SheetSaverProps<S>>> | boolean)[]
    | boolean;
}

interface DirtyReducerAction {
  key: string;
  value: boolean;
}
interface DirtyReducerState {
  [key: string]: boolean;
}
const dirtyReducer = (
  state: DirtyReducerState,
  action: DirtyReducerAction
): DirtyReducerState => {
  return {
    ...state,
    [action.key]: action.value,
  };
};

function Sheet<S extends WP_Post>({
  newDate = false,
  date,
  datePlaces,
  setParentDirty,
  children,
}: SheetProps<S>) {
  // dirty = true <=> data is modified and not saved
  //const [dirty, setDirty] = useState(false);
  const inserting = useRef(false);
  const inserted = useRef<boolean>(!newDate);
  const [dateId, setDateId] = useState(date.id);
  const initialDirty: DirtyReducerState = useMemo(() => {
    let initialDirty = {};
    Children.forEach(children, (child) => {
      // @ts-ignore
      if (child) initialDirty = { ...initialDirty, [child.key]: false };
    });
    return initialDirty;
  }, [children]);
  const [dirty, dispatch] = useReducer(dirtyReducer, initialDirty);

  const cb = useMemo(
    () =>
      Object.fromEntries(
        Object.keys(initialDirty).map((key) => {
          return [key, (val: boolean) => dispatch({ key: key, value: val })];
        })
      ),
    [initialDirty]
  );
  const globalDirty = Object.values(dirty).reduce((a, b) => a || b);
  useEffect(() => {
    setParentDirty(globalDirty);
  }, [globalDirty, setParentDirty]);

  const api = useContext(ApiContext);
  const insert = useCallback(() => {
    if (inserting.current) {
      return;
    } else {
      api.then((api) => {
        if (!inserted.current) {
          inserting.current = true;
          return api
            .date()
            .create({
              date_place: [date.date_place[0].id],
              acf: {
                date: date.acf.date,
              },
            })
            .then((x: any) => {
              inserted.current = true;
              inserting.current = false;
              setDateId(x.id);
              return x.id;
            });
        }
      });
    }
  }, [api, date.acf.date, date.date_place]);

  return (
    <>
      <Card>
        <CardHeader
          action={
            <IconButton size="large">
              <Save />
            </IconButton>
          }
          title={
            <Typography variant="caption">
              {globalDirty ? <i>Modifié</i> : "Enregistré"}
            </Typography>
          }
        />
        <CardContent>
          <Grid container direction="column" justifyContent="center" gap={1}>
            {Children.map(children, (sheet) => {
              if (isValidElement<InjectedSheetDataProps<S>>(sheet)) {
                return cloneElement(sheet, {
                  ...sheet.props,
                  //date: date,
                  newDate,
                  // @ts-ignore
                  setParentDirty: cb[sheet.key],
                  insert: insert,
                  dateId: dateId,
                });
              }
            })}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default Sheet;
