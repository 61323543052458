import { useContext, useEffect } from "react";
import { ApiContext } from "../account/authenticatedAPI";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DClub_User_Role, setRole, setRoleIsLoading } from "./accountSlice";

function useRole(): [boolean, DClub_User_Role[] | undefined] {
  const api = useContext(ApiContext);
  const loaded = useAppSelector((state) => state.account.role !== undefined);
  const isLoading = useAppSelector((state) => state.account.roleIsLoading);
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector((state) => state.account.logged_in);

  useEffect(() => {
    if (!loaded && !isLoading && loggedIn) {
      dispatch(setRoleIsLoading());
      api.then((api) =>
        api
          .users()
          .me()
          .context("edit")
          .then((res: any) => {
            dispatch(setRole(res.roles));
          })
      );
    }
  }, [loaded, isLoading, loggedIn, api, dispatch]);

  return [loaded, useAppSelector((state) => state.account.role)];
}

export default useRole;
