import Sheet from "./sheets/Sheet";
import type {
  DClub_Place_Date,
  DClub_Date,
  WP_Title,
  DClub_Style_Date,
} from "../../apiTypes";
import { format } from "date-fns";
import { PanelSheetDate } from "./sheets/SheetDate";
import { PanelSheetNight } from "./sheets/SheetNight";
import { ORDER } from "./Panel";
import { PanelSheetNightTechnical } from "./sheets/SheetNightTechnical";
import { PanelSheetNightLineUp } from "./sheets/SheetNightLineUp";
import { PanelSheetNightSecurity } from "./sheets/SheetNightSecurity";
import { PanelSheetTechnical } from "./sheets/SheetTechnical";
import { PanelSheetSecurity } from "./sheets/SheetSecurity";
import SheetBasicInfo from "./sheets/SheetBasicInfo";
import { Grid } from "@mui/material";

import type { DClub_User_Role } from "../account/accountSlice";
const emptyTitle = (): WP_Title => ({
  rendered: "",
});
const emptyDateForPlace = (
  place: DClub_Place_Date,
  date: string
): DClub_Date => ({
  id: 0,
  title: emptyTitle(),
  date_place: [place],
  style: [],
  status: "draft",
  featured_media: 0,
  acf: {
    date: date,
  },
});
interface PanelSheetsProps {
  dates: {
    [slug in ORDER]?: DClub_Date;
  };
  date: Date;
  cb: { [k: string]: (dirty: boolean) => void };
  placeDate: DClub_Place_Date[];
  styleDate: DClub_Style_Date[];
  view: Views;
}
enum Views {
  DATE = "Date",
  NIGHT = "Soirée",
  TECHNICAL = "Technique",
  SECURITY = "Sécurité",
}

const ViewsAccess: { [key in Views]: DClub_User_Role[] } = {
  [Views.DATE]: ["administrator", "dclub_admin", "dclub_secretaire"],
  [Views.NIGHT]: [
    "administrator",
    "dclub_admin",
    "dclub_secretaire",
    "dclub_organisteur",
  ],
  [Views.TECHNICAL]: [
    "administrator",
    "dclub_admin",
    "dclub_secretaire",
    "dclub_technician",
  ],
  [Views.SECURITY]: [
    "administrator",
    "dclub_admin",
    "dclub_secretaire",
    "dclub_security",
  ],
};

function filterViewAccess(roles: DClub_User_Role[]): Views[] {
  return Object.values(Views).filter(
    (value) => roles.filter((r) => ViewsAccess[value].includes(r)).length > 0
  );
}
export { filterViewAccess };
export { Views };
function PanelSheets({
  dates,
  date,
  cb,
  placeDate,
  styleDate,
  view,
}: PanelSheetsProps) {
  return (
    <Grid container spacing={2}>
      {Object.entries(dates).map(([slug, d]) => {
        // We need to have a different key for each day so that components
        // state are not persisted across different events
        const key = slug + format(date, "yyyyMMdd");
        // @ts-ignore
        const dateEvent = d
          ? d
          : emptyDateForPlace(
              placeDate.find(
                (place) => place.slug === slug
              ) as DClub_Place_Date,
              format(date, "yyyyMMdd")
            );
        return (
          <Grid item xs={12} sm={6} md={3} key={slug}>
            <Sheet
              newDate={d === undefined}
              key={key}
              date={dateEvent}
              datePlaces={placeDate}
              setParentDirty={cb[slug]}
            >
              {view === Views.DATE ? (
                <PanelSheetDate
                  data={dateEvent}
                  key="place"
                  datePlaces={placeDate}
                  dateStyles={styleDate}
                />
              ) : (
                <SheetBasicInfo
                  data={dateEvent}
                  key="date"
                  datePlaces={placeDate}
                />
              )}

              {(view === Views.NIGHT || view === Views.SECURITY) && (
                <PanelSheetNightSecurity
                  disabled={view !== Views.NIGHT}
                  key="night-sec"
                />
              )}
              {view === Views.NIGHT && <PanelSheetNight key="night" />}
              {(view === Views.NIGHT ||
                view === Views.TECHNICAL ||
                view === Views.SECURITY) && (
                <PanelSheetNightLineUp
                  disabled={view !== Views.NIGHT}
                  key="lup"
                />
              )}
              {view === Views.TECHNICAL && <PanelSheetTechnical key="tec" />}
              {(view === Views.NIGHT || view === Views.TECHNICAL) && (
                <PanelSheetNightTechnical
                  disabled={view !== Views.NIGHT}
                  key="night-tec"
                />
              )}
              {view === Views.SECURITY && <PanelSheetSecurity key="sec" />}
            </Sheet>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default PanelSheets;
