import { useEffect, useState } from "react";
import MultilineText from "../fields/MultilineText";
import withSheetLoader, { InjectedLoadedSheetProps } from "./SheetLoader";
import { withSheetSaver } from "./SheetSaver";
import { DClub_File_Repeater, WP_Post } from "../../../apiTypes";
import MultipleFiles from "../fields/MultipleFiles";
import { DClub_Night } from "./SheetNight";

interface DClub_NightTechnical extends WP_Post {
  acf: {
    soiree_technique: string;
    soiree_bons_boissons_technique: string;
    soiree_decoration_divers: string;
    hospitality_rider?: DClub_File_Repeater[];
    technical_files?: DClub_File_Repeater[];
  };
}
export type { DClub_NightTechnical };
interface SheetNightTechnicalProps
  extends InjectedLoadedSheetProps<DClub_NightTechnical> {
  disabled?: boolean;
}

function SheetNightTechnical({
  data,
  setParentData,
  disabled = false,
}: SheetNightTechnicalProps) {
  const [technique, set_technique] = useState(data.acf.soiree_technique);
  const [bons_boissons_technique, set_bons_boissons_technique] = useState(
    data.acf.soiree_bons_boissons_technique
  );
  const [decoration_divers, set_decoration_divers] = useState(
    data.acf.soiree_decoration_divers
  );
  const [hospitality, set_hospitality] = useState(data.acf.hospitality_rider);
  const [technical_files, set_technical_files] = useState(
    data.acf.technical_files
  );

  useEffect(() => {
    setParentData({
      acf: {
        soiree_technique: technique,
        soiree_bons_boissons_technique: bons_boissons_technique,
        soiree_decoration_divers: decoration_divers,
        hospitality_rider: hospitality,
        technical_files: technical_files,
      },
    });
  }, [
    setParentData,
    technique,
    bons_boissons_technique,
    decoration_divers,
    hospitality,
    technical_files,
  ]);
  return (
    <>
      <MultilineText
        disabled={disabled}
        label="Technique"
        value={technique}
        setValue={set_technique}
      />
      <MultilineText
        disabled={disabled}
        label="Bons boissons technique"
        value={bons_boissons_technique}
        setValue={set_bons_boissons_technique}
      />
      <MultilineText
        disabled={disabled}
        label="Décoration / divers"
        value={decoration_divers}
        setValue={set_decoration_divers}
      />
      <MultipleFiles
        label="Hospitality riders"
        value={hospitality ? hospitality : []}
        setValue={set_hospitality}
      />
      <MultipleFiles
        label="Fichiers techniques"
        value={technical_files ? technical_files : []}
        setValue={set_technical_files}
      />
    </>
  );
}

const empty = {
  id: 0,
  acf: {
    soiree_technique: "",
    soiree_bons_boissons_technique: "",
    soiree_decoration_divers: "",
  },
};

// @ts-ignore
const x = withSheetLoader(
  "date_night_sheet_tec",
  empty,
  withSheetSaver<DClub_Night, SheetNightTechnicalProps>(
    "date_night_sheet_tec",
    SheetNightTechnical
  )
);
// @ts-ignore
const PanelSheetNightTechnical = x as React.ComponentType<
  Partial<SheetNightTechnicalProps>
>;
export { PanelSheetNightTechnical };
