import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://b76793cf00144d2dad0be63454fc4c94@o1164008.ingest.sentry.io/6252759",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router
        basename={
          process.env.PUBLIC_URL && process.env.PUBLIC_URL.length > 0
            ? new URL(process.env.PUBLIC_URL).pathname
            : undefined
        }
        getUserConfirmation={(message, callback) => {
          // this is the default behavior
          const allowTransition = window.confirm(message);
          callback(allowTransition);
        }}
      >
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
