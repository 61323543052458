import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { DClub_Place_Date } from "../../apiTypes";

interface DatePlacesState {
  places: DClub_Place_Date[];
  loaded: boolean;
  isLoading: boolean;
}

const initialState = {
  places: [],
  loaded: false,
  isLoading: false,
} as DatePlacesState;

const datePlacesSlice = createSlice({
  name: "datePlaces",
  initialState,
  reducers: {
    setPlaces(_, action: PayloadAction<DClub_Place_Date[]>) {
      return { places: action.payload, loaded: true, isLoading: false };
    },
    setIsLoading(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export const { setPlaces, setIsLoading, reset } = datePlacesSlice.actions;
export default datePlacesSlice.reducer;
